<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>About Us</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="about-widget-area pt-50 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-widget-img">
                    <img src="assets/img/about/about-img4.png" alt="">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-widget-content">
                    <div class="section-title">
                        <span class="sp-before sp-after">About Us</span>
                        <h2>We are Millennials Inspired by trends</h2>
                    </div>
                    <h3>With over a decade of experience, we are committed to continuous innovation—delivering cutting-edge cybersecurity and app solutions that empower businesses to stay ahead in an ever-evolving digital landscape.</h3>
                    <p>Cyber Development is dedicated to driving innovation by expanding our services and solutions to meet the diverse needs of our clients. We specialize in delivering cutting-edge mobile technology solutions tailored to various industry sectors, ensuring businesses stay secure and competitive in the digital era.</p>
                    <div class="about-widget-list">
                        <ul>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>01</span>
                                <p>We uphold the highest standards of professionalism and integrity, fostering strong and lasting client relationships.</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>We approach challenges with creativity—thinking both inside and outside the box to deliver unique, tailored solutions.</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>We embrace change and innovation, ensuring we never repeat the same approach expecting different results. Instead, we evolve to stay ahead.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="about-video">
                        <a href="https://youtu.be/cZoHIxvwKuk" class="video-btn popup-btn"><i class='flaticon-forward'></i><span>Play Video </span></a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>10 Years</h3>
                    <p>TRENDING YEARS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>100 %</h3>
                    <p>SATISFACTION RATE</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>10 +</h3>
                    <p>TEAM MEMBERS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                  <h3>12 +</h3>
                  <p>AVAILABLE SOLUTIONS</p>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-shape-top">
        <img src="assets/img/counter/counter-shape.png" alt="Images">
    </div>
</div>

<div class="service-list-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2>Our Journey’s Path</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>Our Mission</h3>
                    <p>To provide a one stop shop for Enterprise Mobility and Cyber Security solutions, creating sustainable solutions for our clients.</p>
                    <ul>
                        <li><i class='bx bx-check'></i> Creating Results.</li>
                        <li><i class='bx bx-check'></i> Good Thinking</li>
                        <li><i class='bx bx-check'></i> Expect More</li>
                        <li><i class='bx bx-check'></i> Brilliant Minds</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>Who We Are</h3>
                    <p>A dedicated team of expert engineers, designers, developers, ethical hackers and DevOps will help us churn-out out some
of the highly intuitive, entertaining and user-friendly apps that not only top the charts in app stores</p>
                    <ul>
                        <li><i class='bx bx-check'></i> We Have You Covered</li>
                        <li><i class='bx bx-check'></i> Stay Real Always</li>
                        <li><i class='bx bx-check'></i> We Turn Heads</li>
                        <li><i class='bx bx-check'></i> Stay Real Always</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>Our History</h3>
                    <p>We embark on a journey to tap the potential of mobile computing and smart-phones and to become a
specialist provider of end-to-end solutions in mobile application development around the globe.</p>
                    <ul>
                        <li><i class='bx bx-check'></i> Activate Listening</li>
                        <li><i class='bx bx-check'></i> Better Best Wow!</li>
                        <li><i class='bx bx-check'></i> Brilliant Minds</li>
                        <li><i class='bx bx-check'></i> Activate Listening</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Working Process</span>
            <h2 class="h2-color2">Simple & Clean Work Process</h2>
            <p>The biggest challenges require less time, not more; that individuals produce better solutions than teams; and that you can test anything in one week by building a realistic façade.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li class="text-right">
                            <h3>Frame the Problem <span>1</span></h3>
                            <p>In requirements engineering, requirements elicitation is the practice of researching and discovering the requirements of a system from users, customers, and other stakeholders.</p>
                        </li>
                        <li class="text-right">
                            <h3>Collect the Raw Data <span>2</span></h3>
                            <p>Benchmarking is the practice of comparing business processes and performance metrics to industry bests and best practices from other companies. </p>
                        </li>
                        <li class="text-right">
                            <h3>Process the Data <span>3</span></h3>
                            <p>The big idea with the Design Sprint is to build and test a prototype in just five days. Google, Design Sprints have been run by teams at Slack, Uber, Airbnb, Medium, Dropbox, Facebook and many, many more.</p>
                        </li>
                    </ul>
                </div>
                <div class="about-widget-content">
                <div class="about-video">
                    <a href="https://www.youtube-nocookie.com/embed/K2vSQPh6MCE" class="video-btn popup-btn"><i class='flaticon-forward'></i><span>Watch How We Work!</span></a>
                </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>4</span>Explore the Data</h3>
                            <p>On Monday, you make a map of the problem. On Tuesday, each individual sketches solutions. On Wednesday, you decide which sketches are strongest. On Thursday, you build a realistic prototype. And on Friday, you test that prototype with five target customers. </p>
                        </li>
                        <li>
                            <h3><span>5</span>Design and Development</h3>
                            <p>"Design is not just what it looks like and feels like. Design is how it works." -- Steve Jobs. "Develop with latest programming languages because no one pays for line of codes you've written anymore, is the working product that matters" -- St K` Simon founder of Cyber Development</p>
                        </li>
                        <li>
                            <h3><span>6</span>Testing and Go Live</h3>
                            <p>Our testing methodologies are a various combinations of strategies, principles, ideas, methods, and concepts that help ensure that our software product or an application are bug-free, fit for purpose and well according to user requirements. </p>
                        </li>
                    </ul>
                </div>

            </div>

        </div>
    </div>
</div>

<div class="history-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Our History</span>
            <h2>The Facts About Our Company</h2>
        </div>

        <div class="history-list">
            <ul>
                <li class="active">
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2012</h3>
                        <span>Founded</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2014</h3>
                        <span>Rock-start ⚒️</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2016</h3>
                        <span>Pre-Seed Round 💡</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2018</h3>
                        <span>COVID19</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2022</h3>
                        <span>Seed Round 🌱</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2025</h3>
                        <span>Bridge Round 🔗</span>
                    </div>
                </li>
            </ul>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="history-content">
                    <h2>Our Journey and Vision</h2>
                    <p>At Cyber, we recognized the transformative potential of mobile computing, digital transformation, cybersecurity, IoT, machine learning, and artificial intelligence early on—especially during and beyond the COVID-19 era. With a forward-thinking mindset, we committed ourselves to these emerging industries by building a team of experts capable of delivering innovative solutions that anticipate future trends. </p>
                    <p>Our ability to stay ahead comes from consistently adapting to the evolving mobile computing landscape. Through continuous skill development, technological advancements, and resource investments, we meet industry challenges head-on.</p>
                    <p>We specialize in developing applications across diverse sectors, combining technical expertise with a structured methodology. Our dedicated team of engineers and designers consistently deliver intuitive, engaging, and user-friendly apps. These applications not only achieve top rankings in app stores but also seamlessly integrate into users' everyday lives.</p>

                    <a href="https://sternlysimon.com" class="default-btn">At Cyber, we don't just follow industry trends—we shape the future of technology. <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="history-img">
                    <img src="assets/img/history-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="team-area-two pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Team</span>
            <h2 class="h2-color2">Meet Our Professionals to Grow Your Business</h2>
        </div>

        <div class="team-slider-two owl-carousel owl-theme">
            <div class="team-card active">
                <img src="assets/img/team/cyber-data-scientist.png" alt="Images">
                <div class="content">
                    <h3>Data Scientist</h3>
                    <span>Experts</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated active">
                <img src="assets/img/team/cyber-testers.png" alt="Images">
                <div class="content">
                    <h3>Quality Assurance</h3>
                    <span>Team</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2 active">
                <img src="assets/img/team/cyber-ethical-hacker.png" alt="Images">
                <div class="content">
                    <h3>Ethical hacker</h3>
                    <span>Penetesters</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-card active">
                <img src="assets/img/team/cyber-scrum-master.png" alt="Images">
                <div class="content">
                    <h3>Scrum Master</h3>
                    <span>Team</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2 active">
                <img src="assets/img/team/cyber-blockchain.png" alt="Images">
                <div class="content">
                    <h3>Certified Blockchain</h3>
                    <span>Professional</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-card active">
                <img src="assets/img/team/cyber-machine-learning.png" alt="Images">
                <div class="content">
                    <h3>Machine Learning</h3>
                    <span>Experts</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2 active">
                <img src="assets/img/team/cyber-artificial-intelligence.png" alt="Images">
                <div class="content">
                    <h3>Artifical Intelligence</h3>
                    <span>Engineers</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="team-two-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape7"><img src="assets/img/shape/shape14.png" alt="Images"></div>
    </div>
</div>

<div class="testimonial-area testimonial-area-mb ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="testimonial-list">
                    <div class="list-img-1">
                        <img src="assets/img/testimonial/davidmaesela.png" alt="Images">
                    </div>

                    <div class="list-img-2">
                        <img src="assets/img/testimonial/karthickdorai.png" alt="Images">
                    </div>

                    <div class="list-img-3">
                        <img src="assets/img/testimonial/ardasha.png" alt="Images">
                    </div>

                    <div class="list-img-4">
                        <img src="assets/img/testimonial/dichabelebotse.png" alt="Images">
                    </div>

                    <div class="list-img-5">
                        <img src="assets/img/testimonial/ardasha.png" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="testimonial-title">
                    <h2>.....Testimonials.....</h2>
                </div>

                <div class="testimonial-slider owl-carousel owl-theme">
                    <div class="testimonial-item">
                        <p>good service.</p>
                        <div class="content">
                            <img src="assets/img/testimonial/dichabelebotse.png" alt="Images">
                            <div class="content-title">
                              <h3>Dichabe Lebotse</h3>
                              <span>Atlegang CEO</span>
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                      <p>Awesome team to work on latest technologies.</p>
                        <div class="content">
                            <img src="assets/img/testimonial/ardasha.png" alt="Images">
                            <div class="content-title">
                              <h3>Adarsha Nayak</h3>
                              <span>Reviewer</span>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-item">
                      <p>It’s an excellent company to develop Mobile & Web Applications . A well trained professional experts to give the exact output required.
                        I am very happy to be a part of this company for all my needs , Cyber Development is a one stop solution for all your Software requirements.</p>
                      <div class="content">
                          <img src="assets/img/testimonial/karthickdorai.png" alt="Images">
                          <div class="content-title">
                              <h3>Karthick Dorai</h3>
                              <span>Marketing Manager</span>
                          </div>
                      </div>
                    </div>
                    <div class="testimonial-item">
                      <p>Cyber Development took time to understand our requirements and participated in all our design sprints, long before the actual App development.
                        Sternly and his team have continued to go beyond the call of duty!! Truly great service and solid App Development capabilities..</p>
                      <div class="content">
                          <img src="assets/img/testimonial/davidmaesela.png" alt="Images">
                          <div class="content-title">
                              <h3>David Maesela</h3>
                              <span>CEO & Manager</span>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="brand-logo-area  pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>

<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-widget-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2 class="h2-color">We’re Flexible to <b>Provide You The Best</b></h2>
            <p>At Cyber Development, we specialize in delivering cutting-edge solutions across the most in-demand app services in 2025</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a href="javascript:void(0);"><img src="assets/img/service/service-icon1.png" alt="Images"></a>
                    <h3><a href="javascript:void(0);">Super Apps Development</a></h3>
                    <p>We create comprehensive super apps that combine multiple services—such as messaging, e-commerce, and digital payments—into a seamless, all-in-one platform for enhanced user convenience. </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a href="javascript:void(0);"><img src="assets/img/service/service-icon2.png" alt="Images"></a>
                    <h3><a href="javascript:void(0);">AI-Powered Solutions</a></h3>
                    <p>Our team harnesses artificial intelligence and machine learning to deliver personalized app experiences, predictive analytics, and intelligent automation, ensuring your business stays ahead in a data-driven world.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a href="javascript:void(0);"><img src="assets/img/service/service-icon3.png" alt="Images"></a>
                    <h3><a href="javascript:void(0);">On-Demand Service Apps</a></h3>
                    <p>We design and develop robust on-demand platforms that connect users with essential services like food delivery, ride-hailing, and home assistance, enhancing customer satisfaction and operational efficiency.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a href="javascript:void(0);"><img src="assets/img/service/service-icon4.png" alt="Images"></a>
                    <h3><a href="javascript:void(0);">IoT-Enabled Applications</a></h3>
                    <p>We provide innovative IoT solutions that allow seamless integration with smart devices, empowering users to manage and monitor connected ecosystems through intuitive, user-friendly apps. </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a href="javascript:void(0);"><img src="assets/img/service/service-icon5.png" alt="Images"></a>
                    <h3><a href="javascript:void(0);">Health and Wellness Apps</a></h3>
                    <p>From telehealth platforms to fitness trackers, we craft solutions that cater to the growing demand for accessible, digital health and wellness services.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a href="javascript:void(0);"><img src="assets/img/service/service-icon6.png" alt="Images"></a>
                    <h3><a href="javascript:void(0);">Sustainable Tech Solutions</a></h3>
                    <p>Our commitment to a greener future drives us to build apps that promote sustainability, including energy monitoring, waste reduction, and eco-friendly practices.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <div class="pagination-area text-center">
                <p>We specialize in developing applications across diverse sectors, combining technical expertise with a structured methodology. Our dedicated team of engineers and designers consistently deliver intuitive, engaging, and user-friendly apps. These applications not only achieve top rankings in app stores but also seamlessly integrate into users' everyday lives.</p>
                <a href="https://sternlysimon.com" class="default-btn">At Cyber, we don't just follow industry trends—we shape the future of technology. <i class='bx bx-plus'></i></a>
                </div>
              </div>

            <!-- <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a routerLink="/services" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/services" class="page-numbers">2</a>
                    <a routerLink="/services" class="page-numbers">3</a>
                    <a routerLink="/services" class="page-numbers">4</a>
                    <a routerLink="/services" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div> -->
        </div>
    </div>
</div>

<div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>

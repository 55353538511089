<app-navbar-style-one></app-navbar-style-one>

<div class="banner-area">
    <div class="container-fluid">
        <div class="container-max-2">
            <div class="col-lg-7 col-md-12">
                <div class="banner-content">
                    <div class="title">
                        <i class="flaticon-idea"></i>
                        <span>The Appreneurs</span>
                    </div>
                    <h1>Enterprise Mobility <b> &amp; Cyber Security Consultants</b></h1>
                    <p>We digitally transform businesses and close the digital divide through:-  Digital Transformation, strategy consulting, customer experience and product development respectively. Get affordable mobility solutions and transform your busines today with us!</p>
                    <div class="banner-btn">
                        <a routerLink="/contact" class="get-btn">Get started<i class='bx bx-plus'></i></a>
                        <a href="tel:+27-021-023-2665" class="call-btn"><i class='bx bx-plus'></i>+27 (21) 023-2665</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-shape-right"><img src="assets/img/home1/home-one-shape.png" alt="Images"></div>
    <div class="banner-img"><a href="https://www.appfutura.com/developers/cyber-development" target="_blank" style="cursor: pointer;"><img src="assets/img/home1/home1.png" alt="Cyber Development"></a></div>
    <div class="banner-shape">
        <div class="shape1"><img src="assets/img/home1/home-one-shape3.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/home1/home-one-shape1.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/home1/home-one-shape2.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/home1/home-one-shape4.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/home1/home-one-shape5.png" alt="Images"></div>
    </div>
</div>

<div class="brand-area">
    <div class="container-fluid">
        <div class="brand-max">
            <div class="brand-title">
                <h3>We use <a href="#" target="_blank"> Only The Best</a> design and development tools supported all over the world</h3>
            </div>

            <div class="brand-slider owl-carousel owl-theme">
                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand1.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand2.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand3.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand4.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand5.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand6.png" alt="Images"></a>
                </div>
                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand7.png" alt="Images"></a>
                </div>
                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand8.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand2.png" alt="Images"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container-fluid">
        <div class="container-max">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img-2">
                        <img src="assets/img/about/cyber-best-selling-app.png" alt="Mascot">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <div class="section-title">
                            <span class="sp-after">About us</span>
                            <h2 class="h2-color">WE ARE <b>VISIONARY APPRENEURS</b></h2>
                        </div>
                        <h3>We deliver advanced mobility solutions through the design and development of customized business apps, driving digital transformation and ensuring robust cybersecurity.</h3>
                        <p>At Cyber Development, we offer tailored solutions to meet your business needs. Our expert team of designers, developers, and ethical hackers provides customized services to help your business thrive.How can we help you?</p>
                        <div class="about-btn">
                            <a routerLink="/" class="default-btn">Learn More<i class='bx bx-plus'></i></a>
                            <a href="https://www.youtube.com/watch?v=k4JXG6iSt_E?rel=0" class="play-btn popup-btn"><i class="flaticon-play-button"></i><span> Watch 1min <br>Play demo</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="about-shape">
        <div class="shape-1"><img src="assets/img/about/about-shape1.png" alt="Images"></div>
        <div class="shape-2"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape-3"><img src="assets/img/shape/shape2.png" alt="Images"></div>
        <div class="shape-4"><img src="assets/img/shape/shape3.png" alt="Images"></div>
        <div class="shape-5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
    </div>
</div>

<div class="counter-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-confetti"></i>
                    <div class="content">
                        <h3>10 +</h3>
                        <p>TRADING YEARS</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-project"></i>
                    <div class="content">
                        <h3>10 +</h3>
                        <p>RESOURCE CAPACITY</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-customers"></i>
                    <div class="content">
                        <h3>12 +</h3>
                        <p>AVAILABLE SOLUTIONS</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-financial-advisor"></i>
                    <div class="content">
                        <h3>5 +</h3>
                        <p>STRATEGIC PARTNERS</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="progress-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-after">Real Progress</span>
            <h2 class="h2-color">Download Our Featured <b>Apps available now!</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="progress-card pr-bg-color-1">
                    <img src="assets/img/progress-icon/clickndeliver.png" alt="Images">
                    <h3>clickndeliver</h3>
                    <p>On-Demand Delivery</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="progress-card pr-bg-color-2">
                    <img src="assets/img/progress-icon/lolapp.png" alt="Images">
                    <h3>LearnersOnline</h3>
                    <p>e-Learning AI &amp; AR</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3  offset-lg-0">
                <div class="progress-card pr-bg-color-3">
                    <img src="assets/img/progress-icon/piecejobz.png" alt="Images">
                    <h3>PieceJobz</h3>
                    <p>On-Demand Services</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="seo-area">
    <div class="container-fluid">
        <div class="seo-width">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="seo-form">
                        <h2>Free!Check App Store Optimization</h2>

                        <div class="seo-form-area">
                            <form class="seo-form-option">
                                <div class="row">
                                    <div class="col-lg-4 col-sm-6 col-md-4">
                                        <div class="form-group">
                                            <i class='flaticon-place'></i>
                                            <input class="form-control form-border" type="text" placeholder="Your App Store Link">
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-sm-6 col-md-4">
                                        <div class="form-group">
                                            <i class='flaticon-vision'></i>
                                            <input class="form-control" type="email"  placeholder="Enter Your Email">
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-sm-6 col-md-4 offset-sm-3 offset-md-0 offset-lg-0">
                                        <button type="submit" class="default-btn">
                                            Get started
                                            <i class='bx bx-plus'></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="seo-img">
                        <img src="assets/img/app-platforms1.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sp-after">Services</span>
            <h2 class="h2-color">We’re Flexible to <b>Provide You Best</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="services-card-into">
                    <div class="services-card">
                        <a href="javascript:void(0);"><img src="assets/img/service/service-icon1.png" alt="Images"></a>
                        <h3><a href="javascript:void(0);">App Development</a></h3>
                        <p>Our dedicated team of expert engineers and designers will help us churn-out some of the highly intuitive, entertaining and user-friendly apps that also top the charts in app stores. As we don't only develop we market and advertise. </p>
                        <div class="services-card-shape">
                            <img src="assets/img/service/service-shape.png" alt="Images">
                        </div>
                    </div>
                    <div class="services-dots"><img src="assets/img/service/dots.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-card">
                    <a href="javascript:void(0);"><img src="assets/img/service/service-icon2.png" alt="Images"></a>
                    <h3><a href="javascript:void(0);">Enterprise Mobility Consulantcy</a></h3>
                    <p>If you are preparing for a long-term project, or if you are looking for a suitable extension to your mobile development team, our Dedicated Developer Program (DDP) is a great option. Our app consultancy can be out-sourced. </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>

                <div class="services-card">
                    <a href="javascript:void(0);"><img src="assets/img/service/service-icon3.png" alt="Images"></a>
                    <h3><a href="javascript:void(0);">Outsource App Support</a></h3>
                    <p>We offer complete end-to-end services for mobile application support services and maintenance that works for various industries. We can revamp the UI/UX, test, secure, debug, fix and update/upgrade any of your current apps. </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-card">
                    <a href="javascript:void(0);"><img src="assets/img/service/service-icon4.png" alt="Images"></a>
                    <h3><a href="javascript:void(0);">Code Security (SAST)</a></h3>
                    <p>We would like to interest you in my new service offering Code Security -  Static application security testing (SAST).We will scan your code and identify vulnerabilities. Compliant with stringent security standards including CWE, OWASP, PCI, CERT & SANS.  </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>

                <div class="services-card">
                    <a href="javascript:void(0);"><img src="assets/img/service/service-icon5.png" alt="Images"></a>
                    <h3><a href="javascript:void(0);">Web Development</a></h3>
                    <p>Looking for web application, Cyber Development offers you only to top-norch quality custom and value for your money websites unlike any other service providers we follow best web app practices, guidelines, seach engine optimization and analytics reporting that we monitor in real-time.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>

                <div class="services-card">
                    <a href="javascript:void(0);"><img src="assets/img/service/service-icon6.png" alt="Images"></a>
                    <h3><a href="javascript:void(0);">ICT Skills Training </a></h3>
                    <p>Our team believes in skills exchange to develop other inviduals either in the corporate world or just lay man interested to learn. We offer mobile /web application design and development. As well as Cyber Security training and Awareness.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="services-shape">
        <div class="services-bg"><img src="assets/img/service/service-bg.png" alt="Images"></div>
        <div class="shape1"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape2.png" alt="Images"></div>
    </div>
</div>

<div class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-after">Working Process</span>
            <h2 class="h2-color">Simple & Smooth <b> Agile Process</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="work-card">
                    <h2>01</h2>
                    <h3>Requirements Gathering</h3>
                    <p>In requirements engineering, requirements elicitation is the practice of researching and discovering the requirements of a system from users, customers, and other stakeholders. </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="work-card active">
                    <h2>02</h2>
                    <h3>Design and Development</h3>
                    <p>This process covers goals, procedures, effort, resources and collaboration tools e,g Trello, Slack, Jira, Confluence etc. needed for product design and development. </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="work-card">
                    <h2>03</h2>
                    <h3>Support and Maintenance</h3>
                    <p>Application Support SLA Improve Application Service Providers Performance, by doing DevOps, Monitoring, Security, Audit and backend update &amp; upgrades.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="work-shape"><img src="assets/img/shape/work-shape.png" alt="Images"></div>
</div>

<div class="pricing-area ptb-100">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span class="sp-after">App Requirement Analysis Process</span>
            <h2 class="h2-color">Price & Plans <b>Packages</b></h2>
        </div>

        <div class="price-width">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-banner pricing-icon color-1"></i>
                            <h3 class="color-1">SPRINT</h3>
                            <div class="price-rate">
                                <h2 class="color-1">R6,500.00</h2>
                                <span class="color-1">React Package</span>
                            </div>
                            <ul>
                              <li><i class='bx bx-check'></i>Sprint Plan Consultation</li>
                              <li><i class='bx bx-check'></i>App Conceptualisation</li>
                              <li><del>Documentation Drafts</del></li>
                              <li><i class='bx bx-check'></i>Project Bidding</li>
                              <li><i class='bx bx-check'></i>Minimum Viable Product (MVP)</li>
                              <li><del>Software Requirements Specification (SRS)</del></li>
                              <li><del>Software Design Description (SDD)</del></li>
                              <li><del>Interface Requirements Specification (IRS)</del></li>
                              <li><del>Interface Design Description (IDD)</del></li>
                              <li><del>Software Project Management Plan (SPMP)</del></li>
                              <li><del>Interface Development Plan (IDP)</del></li>
                              <li><del>Software Testing and Modelling Plan (STMP)</del></li>
                              <li><del>Software Verification and Validation Plan (SVVP)</del></li>
                              <li><i class='bx bx-check'></i>UX PROTOTYPE</li>
                            </ul>
                            <!-- <a routerLink="/" class="purchase-btn button-bg1">PURCHASE NOW</a> -->
                            <a data-dpd-type="button" class="purchase-btn button-bg1" data-text="PURCHASE NOW" data-variant="price-right" data-button-size="dpd-large" data-bg-color="469d3d" data-bg-color-hover="5cc052" data-text-color="ffffff" data-pr-bg-color="ffffff" data-pr-color="000000" href="https://cyber-development.dpdcart.com/cart/add?product_id=205089&amp;method_id=223392&amp;referer=https%3A%2F%2Fcyber-development.dpdcart.com%2F">PURCHASE NOW</a><script src="https://cyber-development.dpdcart.com/dpd.js"></script>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-mortgage-loan pricing-icon color-2"></i>
                            <h3 class="color-2">SKETCH</h3>
                            <div class="price-rate">
                                <h2 class="color-2">R 12,000.00</h2>
                                <span class="color-2">Flutter Package</span>
                            </div>
                            <ul>
                              <li><i class='bx bx-check'></i>Discovery Meetings</li>
                              <li><i class='bx bx-check'></i>App Conceptualisation</li>
                              <li><i class='bx bx-check'></i>Documentation Drafts </li>
                              <li><i class='bx bx-check'></i>Project Bidding</li>
                              <li><i class='bx bx-check'></i>Minimum Viable Product (MVP)</li>
                              <li><i class='bx bx-check'></i>Software Requirements Specification (SRS)</li>
                              <li><del>Software Design Description (SDD)</del></li>
                              <li><del>Interface Requirements Specification (IRS)</del></li>
                              <li><del>Interface Design Description (IDD)</del></li>
                              <li><del>Software Project Management Plan (SPMP)</del></li>
                              <li><del>Interface Development Plan (IDP)</del></li>
                              <li><i class='bx bx-check'></i>Software Testing and Modelling Plan (STMP)</li>
                              <li><i class='bx bx-check'></i>Software Verification and Validation Plan (SVVP)</li>
                              <li><i class='bx bx-check'></i>UX PROTOTYPE </li>
                            </ul>
                            <!-- <a routerLink="/" class="purchase-btn button-bg2">PURCHASE NOW</a> -->
                            <a data-dpd-type="button" class="purchase-btn button-bg2" data-text="PURCHASE NOW" data-variant="price-right" data-button-size="dpd-large" data-bg-color="469d3d" data-bg-color-hover="5cc052" data-text-color="ffffff" data-pr-bg-color="ffffff" data-pr-color="000000" data-lightbox="1" href="https://cyber-development.dpdcart.com/cart/add?product_id=205078&amp;method_id=223380&amp;referer=https%3A%2F%2Fcyber-development.dpdcart.com%2F">PURCHASE NOW</a><script src="https://cyber-development.dpdcart.com/dpd.js"></script>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-processing pricing-icon color-3"></i>
                            <h3 class="color-3">INVISION</h3>
                            <div class="price-rate">
                                <h2 class="color-3">R 25,000.00</h2>
                                <span class="color-3">Native Package</span>
                            </div>
                            <ul>
                              <li><i class='bx bx-check'></i>Discovery Consultation</li>
                              <li><i class='bx bx-check'></i>App Conceptualisation</li>
                              <li><i class='bx bx-check'></i>Full Documentation Drafts</li>
                              <li><i class='bx bx-check'></i>Project Bidding</li>
                              <li><i class='bx bx-check'></i>Minimum Viable Product (MVP)</li>
                              <li><i class='bx bx-check'></i>Software Requirements Specification (SRS)</li>
                              <li><i class='bx bx-check'></i>Software Design Description (SDD)</li>
                              <li><i class='bx bx-check'></i>Interface Requirements Specification (IRS)</li>
                              <li><i class='bx bx-check'></i>Interface Design Description (IDD)</li>
                              <li><i class='bx bx-check'></i>Software Project Management Plan (SPMP)</li>
                              <li><i class='bx bx-check'></i>Interface Development Plan (IDP)</li>
                              <li><i class='bx bx-check'></i>Software Testing and Modelling Plan (STMP)</li>
                              <li><i class='bx bx-check'></i>Software Verification and Validation Plan (SVVP)</li>
                              <li><i class='bx bx-check'></i>UX PROTOTYPE </li>
                            </ul>
                            <!-- <a routerLink="/" class="purchase-btn button-bg3">PURCHASE NOW</a> -->
                            <a data-dpd-type="button" class="purchase-btn button-bg3" data-text="PURCHASE NOW" data-variant="price-left" data-button-size="dpd-large" data-bg-color="469d3d" data-bg-color-hover="5cc052" data-text-color="ffffff" data-pr-bg-color="ffffff" data-pr-color="000000" data-lightbox="1" href="https://cyber-development.dpdcart.com/cart/add?product_id=205079&amp;method_id=223381&amp;referer=https%3A%2F%2Fcyber-development.dpdcart.com%2F">PURCHASE NOW</a><script src="https://cyber-development.dpdcart.com/dpd.js"></script>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pricing-shape">
        <div class="shape1"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape8.png" alt="Images"></div>
    </div>
</div>

<!-- <div class="team-area pt-100 pb-70">
    <div class="container-fluid p-0 m-0">
        <div class="section-title text-center">
            <span class="sp-after">Our Specialists</span>
            <h2 class="h2-color">Meet Our <b>Leadership Team</b></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud </p>
        </div>

        <div class="team-slider owl-carousel owl-theme pt-45">
            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before"><img src="assets/img/team/team-img1.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Hobler Jeain</a></h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before-2"><img src="assets/img/team/team-img2.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Jermin Jekson</a></h3>
                    <span>Marketing Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before-3"><img src="assets/img/team/team-img3.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Cristiono Kopper</a></h3>
                    <span>Web Developer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before"><img src="assets/img/team/team-img4.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Julfiker Jeain</a></h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before-2"><img src="assets/img/team/team-img5.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Rickey poltin</a></h3>
                    <span>Area Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before-3"><img src="assets/img/team/team-img6.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Jeck Keplin</a></h3>
                    <span>Web Developer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="team-shape">
        <div class="team-shape1"><img src="assets/img/shape/team-shape.png" alt="Images"></div>
        <div class="team-shape2"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="team-shape3"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="team-shape4"><img src="assets/img/shape/shape2.png" alt="Images"></div>
    </div>
</div>

<div class="blog-area blog-bg pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-title">
                    <div class="section-title">
                        <span class="sp-after">Our Blogs</span>
                        <h2 class="h2-color">Latest Valuable <b>Insights</b></h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="blog-btn">
                    <a routerLink="/blog" class="default-btn">See all news<i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog1.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 09 April 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">The Home of Technology is in Front of You</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog2.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>10 July 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">SEO</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">SEO Best Practices Mobile Friendliness</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog3.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 19 June 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">SEO & Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">15 SEO Practices Website Architecture</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="blog-shape">
        <div class="shape1"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div> -->


<app-footer-style-two></app-footer-style-two>

<footer class="footer-area footer-bg2 pt-100">
    <div class="container">
        <div class="footer-midal pb-70">
            <div class="row">
                <div class="col-lg-4 col-sm-7">
                    <div class="footer-widget">
                        <div class="footer-img">
                            <img src="assets/img/logo/logo1.png" alt="Images">
                        </div>
                        <p>Appreneur is a portmanteau of entrepreneur and application software. They work in the mobile device application industry, as well as in the emerging micropayment economy.</p>
                        <div class="footer-social-icon">
                            <ul class="social-link">
                                <li><a href="https://facebook.com/cyberappdev" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="https://twitter.com/mobicyberdev" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="https://www.instagram.com/developmentcyber/" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <!-- <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> -->
                                <li><a href="https://www.youtube.com/channel/UCaUvV9EQgP0JIDukbqXqvbA" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-5">
                    <div class="footer-widget">
                        <h3>Resources</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/coming-soon">Our Apps</a></li>
                            <li><a routerLink="/coming-soon">Our Codes</a></li>
                            <li><a routerLink="/testimonials">Testimonials</a></li>
                            <li><a routerLink="/">SaaS Solutions</a></li>
                            <li><a routerLink="/">eCommerce</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-7">
                    <div class="footer-widget pl-5">
                        <h3>Company</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/">Home</a></li>
                            <li><a routerLink="/about">About</a></li>
                            <li><a routerLink="/coming-soon">Case Studies</a></li>
                            <li><a routerLink="/coming-soon">Our Blog</a></li>
                            <li><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget">
                        <h3>Address</h3>

                        <ul class="footer-list-two">
                            <a href="tel:+27-021-023-2665"> +27 (21) 023-2665</a>
                            <li><i class='flaticon-email-1'></i><a href="mailto:info&#64;cyberdevelopment.co.za">info&#64;cyberdevelopment.co.za</a></li>
                            <li><i class='flaticon-place'></i>Technopark, Stellenbosch, Cape Town</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copy-right-area">
            <div class="row">
                <div class="col-lg-8">
                    <div class="copy-right-text text-left">
                      <p>Copyright &#64;2025. All Rights Reserved by <a href="https://cyberdevelopment.co.za" target="_blank">Cyber Development.</a> </p>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="copy-right-list">
                        <ul>
                            <li><a routerLink="/privacy-policy"> Privacy Policy</a></li>
                            <li><a routerLink="/terms-conditions"> Terms & Conditions</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
